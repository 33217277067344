import React from 'react'
import MainHeader from 'pages/header/MainHeader'
import Footer from 'pages/footer/Footer'
import { Outlet } from 'react-router-dom'
function Layout(props) {
  return (
    <>
    {/* <MainHeader home ={props.home}/> */}
    <Outlet/>
    {/* <Footer/> */}
    </>
  )
}

export default Layout