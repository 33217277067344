// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAXf25AMFSKTjIN4P44eAYBx9k2j7EEYh8",
  authDomain: "ifruit-tetra.firebaseapp.com",
  projectId: "ifruit-tetra",
  storageBucket: "ifruit-tetra.appspot.com",
  messagingSenderId: "854057539964",
  appId: "1:854057539964:web:4fcf34006389a31b132cf9",
  measurementId: "G-PS2L526LB8"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const perf = getPerformance(firebase);
export default firebase;