import React ,{useState,createContext, useContext} from 'react'


const paymentContext = createContext('')

export function usePayment() {
    return useContext(paymentContext)
}

function PaymentProvider({children}) {
    const initialValues = { nom: "", prenom:"",numDz:"",telephone:"",naissance:"",email: "",wilaya:"",montant:""};
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isOpen,setIsOpen] = useState(false)
    const [payment,setPayment] = useState(false)
    const [tarif,setTarif] = useState(0)
    const [tObject,setTObject] = useState()
    // const errorsValues = {action: "CheckoutError", ErrorCode :"", Desc :"", Desc2 :"", orderId :"", TransactionId :"" }
    const [errValues,setErrorValues]=useState({})
    // condition d'utilisation
    const [isChecked,setIsChecked] = useState(false)
    // recapthcha state
    const [isRecaptcha,setIsRecaptcha] = useState(false)
    // succesful payment data
    const [newSuccessData,setNewSuccessData] = useState({})
    // state to know if the success data received ones before
    //const [isReceived,setIsReceived] = useState(false)
    const paymentContextValue = {errValues,setErrorValues,formValues,setFormValues,formErrors,setFormErrors,isOpen,setIsOpen,payment,setPayment,tarif,setTarif,isChecked,setIsChecked,isRecaptcha,setIsRecaptcha,newSuccessData,setNewSuccessData,tObject,setTObject}







  return (
    <paymentContext.Provider value={paymentContextValue}>
        {children}
    </paymentContext.Provider>
  )
}

export default PaymentProvider