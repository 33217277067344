import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "components/General/Layout";
import PaymentProvider from "contexts/payment-Context/PaymentContext"
// import Contexts 

import firebase from "./firebase";
function App() {
  firebase.automaticDataCollectionEnabled=true;
  return (
    <div className="App">
      <BrowserRouter>
        {" "}
        <PaymentProvider>
        <Routes>
          <Route path="/" element={<Layout home='/'/>}>
          { /*   <Route index element={<CampusInscriptionPage/>}/>
              <Route path="succes" >
                  
                  <Route index  element={<SuccessPage/>}/>
                  <Route path="facture" element={<InvoicePage/>}/>
                </Route>
              <Route path="echec" element={<FailedPaymentPage/>}/>
              <Route path = "processing" element={<CampusProccessingPage/>}/>
              <Route path="conditions-d-utilisation" element={<ConditionsPage/>}/>
*/}
             
          </Route>
          
        </Routes>
        </PaymentProvider>

      </BrowserRouter>

 
    </div>
  );
}

export default App;
